'use client';

import { Box, Center, Heading, Text, Link as ChakraLink } from '@company/ui/components';
import { Trans } from '@lingui/macro';
import Link from 'next/link';

export default function NotFound() {
  return (
    <Center h="100vh" w="100vw">
      <Box textAlign="center">
        <Heading fontSize={'4xl'}>
          <Trans>Page Not Found</Trans>
        </Heading>
        <Text color={'GrayText'}>
          <Trans>Could not find requested resource</Trans>
        </Text>
        <Box mt={4}>
          <ChakraLink asChild color={'blue.500'}>
            <Link href="/home">
              <Trans>Return Home</Trans>
            </Link>
          </ChakraLink>
        </Box>
      </Box>
    </Center>
  );
}
